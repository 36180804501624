import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Excerpt from '../components/excerpt'
import Layout from '../components/layout'
import { NoteTree } from '../components/note-tree'

class NotesPage extends React.Component {
  render() {
    const {
      location,
      data: {
        site: {
          siteMetadata: { title },
        },
        allMarkdownRemark: { edges: posts },
      },
    } = this.props

    return (
      <Layout location={location} sidebar={<NoteTree />}>
        <Helmet title={title} />
        {posts
          .filter(post => post.node.path !== '/404/')
          .map(({ node: { excerpt, frontmatter, fields: { notePath } } }) => (
            <Excerpt
              key={notePath}
              path={notePath}
              title={frontmatter.title}
              date={frontmatter.date}
              excerpt={excerpt}
            />
          ))}
      </Layout>
    )
  }
}

NotesPage.propTypes = {
  location: PropTypes.object,
  route: PropTypes.object,
}

export default NotesPage

export const pageQuery = graphql`
  query NotesQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [fields___notePath], order: ASC }) {
      edges {
        node {
          excerpt(pruneLength: 400)
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            notePath
          }
        }
      }
    }
  }
`
