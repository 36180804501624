import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import styles from './index.module.scss'

export default function Excerpt({ path, title, date, excerpt }) {
  return (
    <section className={styles.root}>
      <h2>
        <Link to={path} className={styles.titleLink}>
          {title}
        </Link>
      </h2>
      <div className={styles.meta}>Post on {date}</div>
      <p
        className={styles.excerpt}
        dangerouslySetInnerHTML={{ __html: excerpt }}
      />
    </section>
  )
}

Excerpt.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
}
